import React from 'react'
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {created_by, copy_text} from '../strings'

class RightFooter extends React.Component {
    render() {
      return (
          <div className="column column-right is-6 social">
            <ul className="is-center">
                <li className="">
                    <a href="https://www.facebook.com/VipCarsSarajevo/" target="_blank"><FontAwesomeIcon icon={['fab', 'facebook-f']} aria-hidden="true" /></a>
                </li>
                <li>
                    <a href="https://www.instagram.com/vipcarssarajevo/" target="_blank"> <FontAwesomeIcon icon={['fab', 'instagram']} aria-hidden="true" /> </a>
                </li>
                <li>
                    <a href="https://twitter.com/vipcarssarajevo" target="_blank"> <FontAwesomeIcon icon={['fab', 'twitter']} aria-hidden="true" /> </a>
                </li>
            </ul>
            <p className="copy">{created_by} <a href="http://www.wellpromotion.ba" target="_blank">Well Business Promotion</a></p>
            <p className="copy mobile">&copy; VIPCARS. {copy_text}</p>
            </div>
      )
    }
  }

export default RightFooter
