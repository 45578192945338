import React from 'react'
import LeftFooter from './leftFooter'
import RightFooter from './rightFooter'
const Footer = ({ siteTitle }) => (
  
<section className="section footer is-full-width is-padding-0">
  <div className="container">
      <div className="columns">
            <LeftFooter/>
            <RightFooter/>
      </div>
  </div>
</section>

)

export default Footer
