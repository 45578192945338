import React from 'react'
import Link from 'gatsby-link'
import {legal_notice, cookies, privacy_statement, contact, copy_text} from '../strings'
import {prefix, link_privacy, link_locations} from '../langPrefix'

function LeftFooter() {
      return (
          <div className="column column-left is-6 footer-menu">
            <ul className="is-center">
                <li className="">
                    <Link to={prefix + link_privacy}>{legal_notice}
                    </Link>
                </li>
                <li>
                    <Link to={prefix + link_privacy}>{cookies}
                    </Link>
                </li>
                <li>
                    <Link to={prefix + link_privacy}>{privacy_statement}
                    </Link>
                </li>
                <li>
                    <Link to={prefix + link_locations}> {contact}
                    </Link>
                </li>
            </ul>
            <p className="copy web">&copy; VIPCARS. {copy_text}</p>
            </div>
      )
  }

export default LeftFooter;
