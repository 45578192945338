import React from "react";
import Link from 'gatsby-link'
import {home, vehicles, locations, about_us} from '../strings'
import {prefix, link_vehicles, link_locations, link_about, link_privacy, link_reserve} from '../langPrefix'

    function Navigation() {
      
            return (
                <ul className="is-center">
                    <li className="mobile">
                        <Link to={prefix + "/"} className="home-menu-item">{home}</Link>
                    </li>
                    <li>
                        <Link to={prefix + link_vehicles} className="vehicles-menu-item">{vehicles}</Link>
                      
                    </li>
                    <li>
                        <Link to={prefix + link_locations} className="locations-menu-item">{locations}</Link>
                    </li>
                    <li>
                        <Link to={prefix + link_about} className="about-menu-item">{about_us}</Link>
                    </li>
                </ul>
          )
        }
        
         
export default Navigation;